export enum QuestionTypes {
  RATING = 'rating',
  MULTI = 'multi',
  OPTION = 'option',
  TEXT = 'text',
  NONE = '',
}

export type TQuestionnaireQuestionAnswerOption = {
  id: number;
  question_id: number;
  text: string;
}

export type TQuestionnaireQuestion = {
  id: number;
  text: string;
  question_type: QuestionTypes;
  answer_options: TQuestionnaireQuestionAnswerOption[];
  is_enabled: boolean;
}

export type TQuestionnaire = {
  id: number;
  title: string;
  questions?: TQuestionnaireQuestion[];
}
