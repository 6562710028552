


import {Vue, Component, Prop} from 'vue-property-decorator';
import promoProgramApi, {TConferenceProgramRating} from '@/_modules/promo-program/api/promo-program.api';
import {TSessionRatings} from '@/_modules/statistics/types/event-stat.type';
import {TConferenceProgram} from '@/_modules/promo/types/conference-program.type';
import IconRatingStar from '@/_modules/icons/components/icon-rating-star.vue';

const STORED_PROGRAM_RATINGS_KEY_NAME = 'storedProgramRatings';

export type TStar = {
  starNumber: number;
}

@Component({
  components: {
    IconRatingStar,
  }
})
export default class ProgramSessionRating extends Vue {

  public stars: TStar[] = [
    {
      starNumber: 1,
    },
    {
      starNumber: 2,
    },
    {
      starNumber: 3,
    },
    {
      starNumber: 4,
    },
    {
      starNumber: 5,
    },
  ];
  public currentRating: number = 0;
  public ratingLoaded: boolean = false;

  @Prop(Object)
  public readonly program: TConferenceProgram;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programId(): number {
    return (this.program && this.program.id) || 0;
  }

  public mounted(): void {
    this.getProgramRating();
  }

  public getProgramRating(): void {
    let result: TConferenceProgramRating = { rating: 0 };
    let storedProgramRatings: TSessionRatings = {};
    try {
      storedProgramRatings = JSON.parse(window.localStorage.getItem(STORED_PROGRAM_RATINGS_KEY_NAME));
      if (Object.prototype.hasOwnProperty.call(storedProgramRatings, this.programId)) {
        result = { rating: storedProgramRatings[this.programId] };
      }
    } catch (error) {
      result = { rating: 0 };
    }

    this.currentRating = result.rating;
    this.ratingLoaded = true;
  }

  public setLocalProgramRating(rating: number): void {
    if (rating < 1 || rating > 5) {
      return;
    }
    this.currentRating = rating;
    this.saveCurrentRating();

    this.setStarHovered({starNumber: this.currentRating});
    this.$nextTick(() => {
      this.setStarHovered({starNumber: this.currentRating});
    });
  }

  public saveCurrentRating(): void {
    let storedProgramRatings: TSessionRatings = {};
    if (window.localStorage && window.localStorage.getItem(STORED_PROGRAM_RATINGS_KEY_NAME)) {
      storedProgramRatings = JSON.parse(window.localStorage.getItem(STORED_PROGRAM_RATINGS_KEY_NAME));
    }
    storedProgramRatings[this.programId] = this.currentRating;
    window.localStorage.setItem(STORED_PROGRAM_RATINGS_KEY_NAME, JSON.stringify(storedProgramRatings));
  }

  public async sendProgramRating(): Promise<void> {
    if (this.currentRating < 1 || this.currentRating > 5) {
      return;
    }
    await promoProgramApi.patchConferenceProgramRating({
      eventId: this.eventId,
      conferenceId: this.program.conference_id,
      programId: this.program.id,
      rating: this.currentRating
    });
    this.closeRatingPopup();
  }

  public isPartialStarNeeded(starNumber: number): boolean {
    return Math.floor(this.currentRating + 1) === starNumber;
  }

  public getPartialStarPercentageWidth(): string {
    const width = ((this.currentRating - Math.floor(this.currentRating)) * 100).toFixed(0);
    const cssUnit = width === '0' ? '' : '%';
    return width + cssUnit;
  }

  public getStarClasses(star: TStar): { [key: string]: boolean } {
    const isFullStar: boolean = Math.floor(this.currentRating) >= star.starNumber;
    const isPartialStar: boolean = this.isPartialStarNeeded(star.starNumber);
    return {
      'full-star': isFullStar,
      'partial-star': isPartialStar
    };
  }

  public setStarHovered(star: TStar): void {
    if (this.$refs.programRatingStars) {
      const refStars: Element[] = (this.$refs.programRatingStars as Element[]);
      for (let i = 0; i < refStars.length; i++) {
        if (i + 1 <= star.starNumber) {
          (refStars[i] as HTMLElement).classList.add('hovered-star');
        } else {
          (refStars[i] as HTMLElement).classList.remove('hovered-star');
        }
      }
    }
  }

  public unsetStarHovered(): void {
    this.setStarHovered({starNumber: -1});
  }

  public closeRatingPopup(): void {
    this.$emit('close');
  };

}
