<template>
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6375 0L18.0463 7.97729L26.6076 9.93112L20.7711 16.8152L21.6535 26L13.6375 22.2773L5.62159 26L6.504 16.8152L0.667468 9.93112L9.22877 7.97729L13.6375 0Z"
      stroke="#00B6F8" fill="#ffffff"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconRatingStar = Vue.extend({
  name: 'icon-rating-star',
});
export default IconRatingStar;
</script>
